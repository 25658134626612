





















































import {Vue,Component,Prop, Watch} from 'vue-property-decorator';
import { ParteDetalleModel, ParteModel } from '@/entidades/Plantacion/Partes/ParteModel';
import moment from 'moment';
import { LaborModel } from '@/entidades/Plantacion/Partes/LaborModel';
import { Mutation, State } from 'vuex-class';
import {Convertir64, _arrayBufferToBase64} from '@/plugins/Util';
const dato = {'parteId':0,'detalleId':0,'imagen':""};
const nameMaestro = "maestroForestal";
const nameForestal = "laboresForestal";
@Component({
    name : 'CierreLabor'
})
export default class CierreLabor extends Vue
{
    @Prop({type:Object,required:true}) labor!:ParteDetalleModel
    @State('labores', {namespace:nameMaestro} ) labores!: LaborModel[];
    @Mutation('setLaborCantidad', {namespace:nameMaestro} ) actualizarCantidadLabor!:Function;
    @Mutation('actualizarDetalle', {namespace:nameForestal} ) actualizarDetalle!:Function;
    cantidad:number=0;
    cantidadPlantula:number=0;
    imagenLabor:any=null;
    image:any=null;
    get cantidadDisponible()
    {
    
        let labor =this.labores.find(c=>c.laborId==this.labor.laborId
            && c.haciendaId == this.labor.haciendaId
            && c.loteId == this.labor.loteId
        );
        return labor!.cantidad;
    }

    get cantidadPlantaDisponible()
    {
       let labor =this.labores.find(c=>c.laborId==this.labor.laborId
            && c.haciendaId == this.labor.haciendaId
            && c.loteId == this.labor.loteId
        );
        return labor!.cantidadPlantula;

    }

    get fechaCierre()
    {
        
        if(this.labor.fechaCierre == "")
        {
            this.labor.fechaCierre = moment(new Date()).format("YYYY-MM-DD") ;
            return moment(new Date()).format("YYYY-MM-DD");
        }
        else
        {
            return this.labor.fechaCierre;
        }
    }

    //#region Metodos Vuex
    cerrarLabor()
    {
     
        dato.parteId = this.labor.parteId;
        dato.detalleId = this.labor.detalleId;
        dato.imagen = this.image;
        if(localStorage.getItem('parteImagen'))
        {
            
            let value = [];
            var value2= localStorage.getItem('parteImagen');
            //@ts-ignore
            value2 = String(value2);
            if(value2 === "undefined")
            {
                value.push(dato);
            }
            else
            {
                //@ts-ignore
                value = JSON.parse(localStorage.getItem('parteImagen'))
            }
           
            //@ts-ignore
            value.push(dato);
            localStorage.removeItem('parteImagen');
            //@ts-ignore
            localStorage.setItem('parteImagen',JSON.stringify(value));

        }
        else
        {
            let datos = [];
            datos.push(dato);
            localStorage.setItem('parteImagen',JSON.stringify(datos));
        }

        this.labor.imagen = this.image; 
        this.labor.cantidad = this.cantidad;
        this.labor.cantidadPlantula = this.cantidadPlantula;
        this.labor.cerrada = true;
        this.actualizarCantidadLabor(this.labor);
        this.actualizarDetalle(this.labor);
        this.$emit('limpiar');
    }
    //#endregion
    //#region Eventos Locales
    handleImage(e:any)
    {
        const selectImagen = e;
        this.imgToBase64(selectImagen).then(c=>{
           this.image = c;
        }); 
    }

    async imgToBase64(data:File){
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(data)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    //#endregion

    //#region WATCH
    
    @Watch('cantidad')
    validarCanitdad(value:number,valueOld:number)
    {
       if(value > this.cantidadDisponible)
       {
           this.cantidad = this.cantidadDisponible;
       }
    }

    @Watch('cantidadPlantula')
    validarCanitdadPlantula(value:number,valueOld:number)
    {
       if(value> this.cantidadPlantaDisponible)
       {
           this.cantidadPlantula = this.cantidadPlantaDisponible;
       }
    }
    //#endregion

    mounted() 
    {
    }

}
